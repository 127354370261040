<script>
import Layout from "../../layouts/main";
import Multiselect from "vue-multiselect";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Swal from "sweetalert2";

import {
  required, url
} from "vuelidate/lib/validators";

/**
 * Form Validation component
 */
export default {
  page: {
    title: "Form Create Banner",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { Layout, PageHeader, Multiselect },
  data() {
    return {
      title: "Form Create Banner",
      items: [
        {
          text: "Home",
          href: "/home"
        },
        {
          text: "Banners",
          href: "/banner"
        },
        {
          text: "Create Banner",
          active: true
        }
      ],
      form: {
        title: null,
        subtitle: null,
        image: null,
        button_text: null,
        button_link: null,
        status: null
      },
      options: [
        { name: 'Active', value: 1 },
        { name: 'Not Active', value: 0 }
      ],
      isStatusNotSelected: false,
      isImageEmpty: false,
      preview: null,
      typesubmit: false,
      isError: false,
      errorMessage: null
    };
  },
  validations: {
    form: {
      title: { required },
      subtitle: { required },
      button_text: { required },
      button_link: { required, url },
    }
  },
  methods: {
    onSubmit(e) {
      this.typesubmit = true;
      // stop here if form is invalid
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.isError = true
        this.errorMessage = "please fill the form with valid data"
        this.isStatusNotSelected = (this.form.status === null)? true : false
        this.isImageEmpty = (this.form.image === null)? true : false
      } else {
        // do your submit logic here
        this.isStatusNotSelected = (this.form.status === null)? true : false
        this.isImageEmpty = (this.form.image === null)? true : false

        if(this.isStatusNotSelected || this.isImageEmpty) {
          return false
        }

        let param = new FormData();
        param.append('big_header', this.form.title) 
        param.append('small_header', this.form.subtitle)
        param.append('button_text', this.form.button_text)
        param.append('button_link', this.form.button_link)
        param.append('enable', this.form.status.value)
        param.append('banner', this.form.image)

        this.$axios.post('/banner', param)
        .then( (result) => { 
          console.log(result.data)
          this.isError = false
          Swal.fire("Success", "Banner Created Successfully", "success");
          this.$router.push('/banner')
        }).catch(e => {
          if (e.response) {  
            this.isError = true
            this.errorMessage = e.response.data.message
          }
        })
      }
    },
    upload(event) {
      if(event.target.files[0] !== undefined){
        let extension = event.target.files[0].name.split(".").pop()
        let allowedExtention = ['jpg','jpeg','png','svg']
        if(allowedExtention.includes(extension.toLowerCase())){
          this.form.image = event.target.files[0]
          this.preview = URL.createObjectURL(this.form.image);
          this.errorMessage = null
          this.isError = false
        }else{
          this.isError = true
          this.errorMessage = "image extension is not allowed"
          this.form.image = null
          document.getElementById('image').value = null
        }
      }
    }
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <b-alert
              v-model="isError"
              variant="danger"
              class="mt-3"
            >{{errorMessage}}</b-alert>
            <form action="#" @submit.prevent="onSubmit">
              <div class="form-group">
                <label>Title</label>
                <input
                  v-model="form.title"
                  type="text"
                  class="form-control"
                  name="title"
                  :class="{ 'is-invalid': typesubmit && $v.form.title.$error }"
                />
                <div v-if="typesubmit && $v.form.title.$error" class="invalid-feedback">
                  <span v-if="!$v.form.title.required">This value is required.</span>
                </div>
              </div>

              <div class="form-group">
                <label>Subtitle</label>
                <input
                  v-model="form.subtitle"
                  type="text"
                  class="form-control"
                  name="subtitle"
                  :class="{ 'is-invalid': typesubmit && $v.form.subtitle.$error }"
                />
                <div v-if="typesubmit && $v.form.subtitle.$error" class="invalid-feedback">
                  <span v-if="!$v.form.subtitle.required">This value is required.</span>
                </div>
              </div>

              <div class="form-group">
                <label>Image</label>
                <input :class="{ 'is-invalid': isImageEmpty }" type="file" id="image" ref="image" accept=".jpg, .jpeg, .png" @change="upload" class="form-control" name="image" />
                <div id="backgroundUploadHelp" class="form-text">File Type .jpg, .jpeg, .png </div>
                <div v-if="isImageEmpty" class="invalid-feedback">
                  <span>This value is required.</span>
                </div>
              </div>
              <div id="perview">
                <b-img v-if="preview" :src="preview" thumbnail fluid></b-img>
              </div>

              <div class="form-group">
                <label>Button Text</label>
                <input
                  v-model="form.button_text"
                  type="text"
                  class="form-control"
                  name="button_text"
                  :class="{ 'is-invalid': typesubmit && $v.form.button_text.$error }"
                />
                <div v-if="typesubmit && $v.form.button_text.$error" class="invalid-feedback">
                  <span v-if="!$v.form.button_text.required">This value is required.</span>
                </div>
              </div>

              <div class="form-group">
                <label>Button Link</label>
                <input
                  v-model="form.button_link"
                  type="text"
                  class="form-control"
                  name="button_link"
                  :class="{ 'is-invalid': typesubmit && $v.form.button_link.$error }"
                />
                <div v-if="typesubmit && $v.form.button_link.$error" class="invalid-feedback">
                  <span v-if="!$v.form.button_link.required">This value is required.</span>
                  <br>
                  <span v-if="!$v.form.button_link.url">This value must URL format.</span>
                </div>
              </div>

              <div class="form-group">
                <label :class="{ 'is-invalid': isStatusNotSelected }">Status</label>
                <multiselect v-model="form.status" deselect-label="Can't remove this value" track-by="name" label="name" placeholder="Select one" :options="options" :searchable="false" :allow-empty="false">
                  <template slot="singleLabel" slot-scope="{ option }"><strong>{{ option.name }}</strong></template>
                </multiselect>
                <div v-if="isStatusNotSelected" class="invalid-feedback">
                  <span>This value is required.</span>
                </div>
              </div>

              <div class="form-group mb-0">
                <div>
                  <button type="submit" class="btn btn-primary">Submit</button>
                </div>
              </div>
            </form>
          </div>
          <!-- end card-body -->
        </div>
      </div>
      <!-- end col-->
    </div>
    <!-- end row -->
  </Layout>
</template>

<style scoped>
  .multiselect__tags {
    border-color: red !important;
  }
</style>